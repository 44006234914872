import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

export const trackEvent = (eventName, eventParams = {}) => {
  logEvent(analytics, eventName, eventParams);
};

export const trackLogin = (userId) => {
  trackEvent('login', { userId });
};

export const trackCreateRoom = (roomId, isPersonal) => {
  const creationTime = new Date().toISOString();
  trackEvent('create_room', { roomId, isPersonal, creationTime });
};

export const trackJoinRoom = (roomId) => {
  const joinTime = new Date().toISOString();
  trackEvent('join_room', { roomId, joinTime });
};

export const trackPlay = () => {
  const playTime = new Date().toISOString();
  trackEvent('play_metronome', { playTime });
};

export const trackUserRetention = () => {
  const lastRetentionDate = localStorage.getItem('lastRetentionDate');
  const today = new Date().toDateString();

  if (lastRetentionDate !== today) {
    logEvent(analytics, 'user_retention', {
      date: today
    });
    localStorage.setItem('lastRetentionDate', today);
  }
};

// 새로운 추적 함수들

export const trackAlbumClick = (albumId) => {
  trackEvent('album_click', { albumId });
};

export const trackAlbumViewDuration = (albumId, duration) => {
  trackEvent('album_view_duration', { albumId, duration });
};

export const trackMetronomePlayDuration = (duration) => {
  trackEvent('metronome_play_duration', { duration });
};

export const trackRoomDuration = (roomId, duration) => {
  trackEvent('room_duration', { roomId, duration });
};

export const trackFeatureUsage = (featureName) => {
  trackEvent('feature_usage', { featureName });
};


export const trackSharedRoom = (roomId) => {
  trackEvent('shared_room', { roomId });
};