// metronome.js

import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { useNetworkLatencyStore } from './networkLatencyStore';
import { useXMLParserStore } from '@/store/XMLParser.js';
import { useScoreInfoStore } from './InfoParser';
import { useRoomStore } from './room';
import * as Tone from 'tone';
import { trackPlay } from '@/analytics';

export const useMetronomeStore = defineStore('metronome', () => {
    const isPlaying = ref(false);
    const currentBeat = ref(1);
    const prebeatCount = ref(0);
    const bpm = ref(120);
    const timeSignature = ref('4/4');
    const volume = ref(0.5);
    const soundType = ref('sine');
    const perfTimeOrigin = performance.timeOrigin;
    const audioDeviceDelay = ref(Math.max(0, parseInt(localStorage.getItem('metronomeAudioDeviceDelay') || '1')));

    // 그래픽 표시를 위한 현재 마디
    const currentMeasure = ref(1);

    // 메트로놈에 사용되는 실제 마디 수
    const measureCount = ref(1);

    const isPaused = ref(true);
    const prebeat = ref(Math.max(0, parseInt(localStorage.getItem('prebeat') || '0')));
    const lastStoppedMeasure = ref(1);
    const isFirstBeat = ref(true);

    const isPrebeat = ref(false);

    const networkLatencyStore = useNetworkLatencyStore();
    const scoreInfoStore = useScoreInfoStore();
    const xmlParserStore = useXMLParserStore();
    const roomStore = useRoomStore();

    const totalMeasures = computed(() => {
        return typeof scoreInfoStore.getTotalMeasures === 'function'
            ? scoreInfoStore.getTotalMeasures()
            : scoreInfoStore.getTotalMeasures;
    });

    let timerID = null;

    let synth;
    let stickSynth;

    const beatsPerMeasure = computed(() => {
        const [numerator] = timeSignature.value.split('/').map(Number);
        return numerator || 4;
    });

    const ensureLatencyMeasurement = async () => {
        if (networkLatencyStore.isRemeasuring) {
            console.log('Latency measurement already in progress. Waiting for it to complete...');
            // 이미 측정 중이라면, 측정이 완료될 때까지 기다립니다.
            while (networkLatencyStore.isRemeasuring) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            return;
        }

        if (!networkLatencyStore.isInitialized) {
            console.log('Initializing network latency measurement...');
            await networkLatencyStore.setupLatencyMeasurement();
        }
        console.log('Network latency measurement completed.');
    };

    const initializeDelays = () => {
        if (!localStorage.getItem('metronomeAudioDeviceDelay')) {
            audioDeviceDelay.value = 1;
            localStorage.setItem('metronomeAudioDeviceDelay', '1');
        }
    };

    initializeDelays();
    networkLatencyStore.setupLatencyMeasurement();

    const initializeSynths = () => {
        synth = new Tone.MembraneSynth({
            pitchDecay: 0.008,
            octaves: 2,
            oscillator: {
                type: soundType.value
            },
            envelope: {
                attack: 0.0006,
                decay: 0.5,
                sustain: 0,
                release: 0.1
            }
        }).toDestination();
        synth.volume.value = Tone.gainToDb(volume.value);

        stickSynth = new Tone.NoiseSynth({
            noise: {
                type: 'white',
                playbackRate: 3,
                volume: -5,
            },
            envelope: {
                attack: 0.001,
                decay: 0.05,
                sustain: 0,
                release: 0.05
            }
        }).toDestination();
        stickSynth.volume.value = Tone.gainToDb(volume.value);
    };

    const userBPMOverride = ref(false); // 사용자가 BPM을 수동으로 변경했는지 추적하는 플래그

    const updateBPM = (newBPM, source = 'user') => {
        bpm.value = newBPM;
        if (source === 'user') {
            userBPMOverride.value = true; // 사용자가 BPM을 변경하면 플래그 설정
        }
        if (synth) {
            synth.volume.value = Tone.gainToDb(volume.value);
            synth.oscillator.type = soundType.value; // 추가: 오실레이터 타입 업데이트
        }
        if (stickSynth) {
            stickSynth.volume.value = Tone.gainToDb(volume.value);
        }
        if (roomStore.currentRoom) {
            roomStore.updateRoomSettings(roomStore.currentRoom, { bpm: newBPM });
        }
    };

    const updateTimeSignature = (newTimeSignature) => {
        timeSignature.value = newTimeSignature;
    };

    const soundSchedule = ref([]);

    const start = async (serverStartTime = null, lastStoppedBeat = 1) => {
        if (isPlaying.value) return;

        isPlaying.value = true;
        isPaused.value = false;

        await ensureLatencyMeasurement();

        // Tone.js 컨텍스트 시작 확인
        if (Tone.context.state !== 'running') {
            try {
                await Tone.start();
                console.log('Audio context started successfully');
            } catch (error) {
                console.log('Failed to start audio context:', error);
                return; // 오디오 컨텍스트를 시작할 수 없으면 함수 종료
            }
        }

        soundSchedule.value = generateSoundSchedule();

        if (isPaused.value) {
            currentMeasure.value = Math.max(1, lastStoppedMeasure.value);
            measureCount.value = currentMeasure.value;  // Initialize measureCount
            currentBeat.value = lastStoppedBeat;
            prebeatCount.value = Math.max(0, prebeat.value * beatsPerMeasure.value);
        } else {
            currentBeat.value = 1;
            currentMeasure.value = lastStoppedMeasure.value;
            measureCount.value = currentMeasure.value;  // Initialize measureCount
            prebeatCount.value = Math.max(0, prebeat.value * beatsPerMeasure.value);
        }
        Tone.start();
        trackPlay();
        initializeSynths();

        const startTime = serverStartTime || (networkLatencyStore.getCurrentServerTime() + 1000);
        scheduleMetronome(startTime);
    };

    const generateSoundSchedule = (startMeasure) => {
        const schedule = [];
        let currentTime = 0;

        if (roomStore.currentRoomData?.currentAlbum == null) {
            console.log('No album selected, using room settings');
            const beatsPerMeasure = parseInt(timeSignature.value.split('/')[0]) || 4;
            const beatType = parseInt(timeSignature.value.split('/')[1]) || 4;
            let beatDuration = 60000 / bpm.value;

            // Add prebeat measures
            const prebeatMeasures = roomStore.currentRoomData?.prebeat || 0;
            for (let measure = -prebeatMeasures; measure < 0; measure++) {
                for (let beat = 1; beat <= beatsPerMeasure; beat++) {
                    schedule.push({
                        type: 'prebeat',
                        measure: 0,
                        beat: beat,
                        time: currentTime,
                        bpm: bpm.value
                    });
                    currentTime += beatDuration;
                }
            }

            if (beatType === 8) {
                beatDuration /= 2;
            }

            for (let measure = 1; measure <= 1000; measure++) {
                for (let beat = 1; beat <= beatsPerMeasure; beat++) {
                    schedule.push({
                        type: 'beat',
                        measure: measure,
                        beat: beat,
                        time: currentTime,
                        bpm: bpm.value
                    });
                    currentTime += beatDuration;
                }
            }
            return schedule;
        }

        const measureList = xmlParserStore.scorePartwise?.parts.find(part => part.id === 'P1')?.measures;

        // First pass: Collect basic info
        const measureInfoList = measureList.map(measure => {
            const measureNumber = measure.number;

            // Time signature extraction
            const timeSignature = measure.attributes?.time
                ? `${measure.attributes.time.beats}/${measure.attributes.time.beatType}`
                : 'N/A';

            // BPM extraction
            let bpm = 'N/A';
            for (const element of measure.elements) {
                if (element.directionType?.metronome?.perMinute) {
                    bpm = element.directionType.metronome.perMinute;
                    break;
                } else if (element.tempo) {
                    bpm = element.tempo;
                    break;
                }
            }

            return {
                measureNumber,
                timeSignature,
                bpm
            };
        });

        // Second pass: Fill in missing info from previous measures
        for (let i = 1; i < measureInfoList.length; i++) {
            if (measureInfoList[i].timeSignature === 'N/A') {
                measureInfoList[i].timeSignature = measureInfoList[i - 1].timeSignature;
            }
            if (measureInfoList[i].bpm === 'N/A') {
                measureInfoList[i].bpm = measureInfoList[i - 1].bpm;
            }
        }

        // startMeasure부터 시작하는 measureInfoList의 하위 배열 생성
        const relevantMeasures = measureInfoList.slice(startMeasure - 1);

        // Add prebeat measures before the actual score
        const prebeatMeasures = roomStore.currentRoomData?.prebeat || 0;
        for (let i = 0; i < prebeatMeasures; i++) {
            const measureInfo = measureInfoList[0]; // Use the first measure's info for prebeat
            const currentBPM = parseInt(measureInfo.bpm);
            const [beatsPerMeasure, beatType] = measureInfo.timeSignature.split('/').map(Number);
            let beatDuration = 60000 / currentBPM;

            if (beatType === 8) {
                beatDuration /= 2;
            }

            for (let beat = 1; beat <= beatsPerMeasure; beat++) {
                schedule.push({
                    type: 'prebeat',
                    measure: startMeasure,
                    beat: beat,
                    time: currentTime,
                    bpm: currentBPM
                });
                currentTime += beatDuration;
            }
        }

        relevantMeasures.forEach((measureInfo, index) => {
            const currentMeasure = startMeasure + index;
            const currentBPM = parseInt(measureInfo.bpm);
            const [beatsPerMeasure, beatType] = measureInfo.timeSignature.split('/').map(Number);

            if (isNaN(currentBPM) || isNaN(beatsPerMeasure) || isNaN(beatType)) {
                console.error(`Invalid data for measure ${currentMeasure}:`, measureInfo);
                return; // 이 measure를 건너뜁니다
            }

            let beatDuration = 60000 / currentBPM;

            for (let beat = 1; beat <= beatsPerMeasure; beat++) {
                schedule.push({
                    type: 'beat',
                    measure: currentMeasure,
                    beat: beat,
                    time: currentTime,
                    bpm: currentBPM
                });
                currentTime += beatDuration;
            }
        });

        return schedule;
    };

    const scheduleMetronome = (startTime) => {
        if (!isPlaying.value) return;

        const currentTime = perfTimeOrigin + performance.now();
        const syncedCurrentTime = currentTime + networkLatencyStore.averageTimeOffset;
        let elapsedTime = syncedCurrentTime - startTime;

        if (!window.scheduledTimers) {
            window.scheduledTimers = [];
        }

        // lastStoppedMeasure부터 새로운 스케줄 생성
        let newSchedule = generateSoundSchedule(lastStoppedMeasure.value);

        // 시작 시간을 1000ms로 조정
        const initialDelay = newSchedule[0]?.time || 0;
        newSchedule.forEach(beat => {
            beat.time = beat.time - initialDelay + 1000;
        });

        // 경과 시간이 음수인 경우 처리
        if (elapsedTime < 0) {
            console.warn('Negative elapsed time detected. Adjusting schedule.');
            // 경과된 시간만큼의 비트를 제거
            newSchedule = newSchedule.filter(beat => beat.time >= Math.abs(elapsedTime));
        } else {
            // 이미 지난 비트 제거
            newSchedule = newSchedule.filter(beat => beat.time > elapsedTime);
        }

        const scheduleAheadTime = 0.1; // 100ms ahead scheduling
        let lastScheduledTime = 0;

        newSchedule.forEach((beat) => {
            const delay = beat.time - elapsedTime;
            if (delay > 0) {
                const timerId = setTimeout(() => {
                    if (isPlaying.value) {
                        const audioContextTime = Tone.getContext().currentTime;
                        const scheduleTime = Math.max(audioContextTime, lastScheduledTime) + scheduleAheadTime;
                        playMetronomeSound(beat, scheduleTime);
                        lastScheduledTime = scheduleTime;
                    }
                }, delay - scheduleAheadTime * 1000);
                window.scheduledTimers.push(timerId);
            }
        });
    };

    const playMetronomeSound = (beat, scheduleTime) => {
        if (beat.type === 'prebeat') {
            stickSynth.triggerAttackRelease('8n', scheduleTime);
            isPrebeat.value = true;
        } else {
            const note = beat.beat === 1 ? 'E5' : 'C5';
            synth.triggerAttackRelease(note, '16n', Tone.now());
            isPrebeat.value = false;
        }
        Tone.Draw.schedule(() => {
            currentBeat.value = beat.beat;
            currentMeasure.value = beat.measure;
            console.log(`Beat: ${currentBeat.value}, Measure: ${currentMeasure.value}, Time: ${scheduleTime}, Time Offset: ${networkLatencyStore.averageTimeOffset}ms, isPrebeat : ${isPrebeat.value}`);
        }, scheduleTime);
    };

    const stop = () => {
        isPlaying.value = false;
        isPaused.value = true;
        prebeatCount.value = 0;
        lastStoppedMeasure.value = measureCount.value;
        currentMeasure.value = measureCount.value;
        currentBeat.value = 1;

        // 모든 예약된 타이머 취소
        if (window.scheduledTimers) {
            window.scheduledTimers.forEach(timerId => clearTimeout(timerId));
            window.scheduledTimers = [];
        }

        // Tone.js 관련 정리
        Tone.Transport.stop();
        Tone.Transport.cancel();

        if (synth) {
            synth.dispose();
            synth = null;
        }
        if (stickSynth) {
            stickSynth.dispose();
            stickSynth = null;
        }

        // 스케줄 초기화
        soundSchedule.value = [];

        isFirstBeat.value = true;
        resetOverride();
    };


    const pause = () => {
        if (!isPlaying.value) return;
        lastStoppedMeasure.value = measureCount.value;  // Use measureCount
        isPlaying.value = false;
        isPaused.value = true;
        clearTimeout(timerID);
        isFirstBeat.value = true;

        // 모든 예약된 타이머 취소
        if (window.scheduledTimers) {
            window.scheduledTimers.forEach(timerId => clearTimeout(timerId));
            window.scheduledTimers = [];
        }
        // 스케줄 초기화
        soundSchedule.value = [];
        isFirstBeat.value = true;
    };

    const resetMetronome = () => {
        stop();
        currentBeat.value = 1;
        currentMeasure.value = 1;
        measureCount.value = 1;
        lastStoppedMeasure.value = 1;
    };

    const resume = () => {
        if (!isPaused.value) return;
        start(null, prebeat.value, currentBeat.value);
    };

    const updateVolume = (newVolume) => {
        volume.value = newVolume;
        if (synth) {
            synth.volume.value = Tone.gainToDb(newVolume);
        }
        if (stickSynth) {
            stickSynth.volume.value = Tone.gainToDb(newVolume);
        }
    };

    const updateSoundType = (newSoundType) => {
        soundType.value = newSoundType;
        if (synth) {
            synth.oscillator.type = newSoundType;
        }
    };

    const updateAudioDeviceDelay = (newDelay) => {
        const delay = Math.max(0, parseInt(newDelay));
        audioDeviceDelay.value = delay;
        localStorage.setItem('metronomeAudioDeviceDelay', delay.toString());
    };

    const resetCurrentBeat = () => {
        currentBeat.value = 1;
        currentMeasure.value = 0;
    };

    const updatePrebeat = (newPrebeat) => {
        prebeat.value = Math.max(0, parseInt(newPrebeat));
        console.log('Updating Prebeat:', prebeat.value);
    };

    const setCurrentBeat = (beat) => {
        if (!isPlaying.value) {
            currentBeat.value = beat;
        }
    };

    const toggleMetronome = async () => {
        if (!roomStore.isInRoom) return;

        if (isPlaying.value) {
            pause();
            await roomStore.updateRoomSettings(roomStore.currentRoom, {
                isPlaying: false,
                lastStoppedMeasure: currentMeasure.value
            });
        } else {
            const startTime = networkLatencyStore.getCurrentServerTime() + 1000;

            await roomStore.updateRoomSettings(roomStore.currentRoom, {
                isPlaying: true,
                startTime: startTime,
                bpm: bpm.value,
                // prebeat: prebeat.value,
                timeSignature: timeSignature.value,
                lastStoppedMeasure: lastStoppedMeasure.value - 1,
            });

            start(startTime, prebeat.value);
        }
    };

    const increaseCurrentMeasure = () => {
        if (isPlaying.value) {
            console.log("Cannot change measure while playing");
            return;
        }

        if (typeof totalMeasures.value === 'number' && currentMeasure.value < totalMeasures.value - 1) {
            currentMeasure.value++;
            lastStoppedMeasure.value = currentMeasure.value;
            if (roomStore.currentRoom) {
                roomStore.updateRoomSettings(roomStore.currentRoom, {
                    lastStoppedMeasure: currentMeasure.value,
                    lastStoppedBeat: currentBeat.value
                });
            }
        }
    };

    const decreaseCurrentMeasure = () => {
        if (isPlaying.value) {
            return;
        }

        if (typeof totalMeasures.value === 'number' && currentMeasure.value > 1) {
            currentMeasure.value--;
            lastStoppedMeasure.value = currentMeasure.value;
            if (roomStore.currentRoom) {
                roomStore.updateRoomSettings(roomStore.currentRoom, {
                    lastStoppedMeasure: currentMeasure.value,
                    lastStoppedBeat: currentBeat.value
                });
            }
        }
    };

    const setCurrentMeasure = (measure) => {
        if (!isPlaying.value) {
            currentMeasure.value = measure;
            measureCount.value = measure;  // Update measureCount as well
            lastStoppedMeasure.value = measure;
        }
    };

    const jumpToMeasure = (measure) => {
        if (isPlaying.value) {
            return;
        }

        if (measure < 1 || measure > totalMeasures.value) {
            return;
        }

        currentMeasure.value = measure;
        measureCount.value = measure;  // Update measureCount as well
        lastStoppedMeasure.value = measure;
        currentBeat.value = 1;

        if (roomStore.currentRoom) {
            roomStore.updateRoomSettings(roomStore.currentRoom, {
                lastStoppedMeasure: measure,
                lastStoppedBeat: 1
            });
        }
    };

    watch(() => roomStore.currentRoomData?.lastStoppedMeasure, (newMeasure) => {
        if (!isPlaying.value && newMeasure !== undefined && newMeasure !== currentMeasure.value) {
            setCurrentMeasure(newMeasure);
        }
    });

    watch(() => roomStore.currentRoomData?.lastStoppedBeat, (newBeat) => {
        if (!isPlaying.value && newBeat !== undefined && newBeat !== currentBeat.value) {
            setCurrentBeat(newBeat);
        }
    });

    watch(() => roomStore.currentRoomData, (newRoomData) => {
        if (newRoomData && !isPlaying.value) {
            setCurrentMeasure(newRoomData.lastStoppedMeasure || 1);
            setCurrentBeat(newRoomData.lastStoppedBeat || 1);
        }
    }, { immediate: true });

    // Override 플래그를 초기화하는 함수
    const resetOverride = () => {
        userBPMOverride.value = false;
    };


    return {
        isPlaying,
        currentBeat,
        prebeatCount,
        bpm,
        timeSignature,
        volume,
        soundType,
        audioDeviceDelay,
        beatsPerMeasure,
        currentMeasure,
        totalMeasures,
        isPaused,
        prebeat,
        lastStoppedMeasure,
        start,
        stop,
        pause,
        resetMetronome,
        resume,
        updateBPM,
        updateTimeSignature,
        updateVolume,
        updateSoundType,
        updateAudioDeviceDelay,
        resetCurrentBeat,
        updatePrebeat,
        toggleMetronome,
        decreaseCurrentMeasure,
        increaseCurrentMeasure,
        jumpToMeasure,
        setCurrentMeasure,
        setCurrentBeat,
        isPrebeat
    };
});