/* eslint-disable */
// renderer.js
import { Attributes, Note, Harmony, Backup, Forward } from './class.js';

export class Renderer {
    constructor(scorePartwise) {
        this.scorePartwise = scorePartwise;
        this.currentKeySignature = null; // 현재 조표 상태를 추적
    }

    // MusicXML의 type을 VexFlow의 duration으로 변환하는 헬퍼 함수
    mapDuration(type) {
        const durationMap = {
            'maxima': 'w',
            'long': 'w',
            'breve': 'w',
            'whole': 'w',
            'half': 'h',
            'quarter': 'q',
            'eighth': '8',
            '16th': '16',
            '32nd': '32',
            '64th': '64',
            '128th': '128',
            '256th': '256'
        };
        return durationMap[type] || 'q'; // 기본값은 'quarter'로 설정
    }

    // print-object 속성을 확인하는 헬퍼 함수
    shouldPrint(element) {
        if (!element) return true;
        return element.printObject !== false;
    }

    // print-spacing 속성을 확인하는 헬퍼 함수
    shouldPrintSpacing(element) {
        if (!element) return true;
        return element.printSpacing !== false;
    }

    // 속성을 병합하는 함수
    mergeAttributes(prevAttr, currAttr) {
        if (!prevAttr) return currAttr || {};
        if (!currAttr) return prevAttr;

        const mergedAttr = { ...prevAttr };

        // 현재 마디에 새로운 속성이 있으면 업데이트
        if (currAttr.divisions) mergedAttr.divisions = currAttr.divisions;
        if (currAttr.key) mergedAttr.key = currAttr.key;
        if (currAttr.time) mergedAttr.time = currAttr.time;
        if (currAttr.clef) mergedAttr.clef = currAttr.clef;
        if (currAttr.transpose) mergedAttr.transpose = currAttr.transpose;

        // 추가로 필요한 속성이 있다면 여기에 추가

        return mergedAttr;
    }

    // 속성 변경 여부를 확인하는 함수
    compareAttributes(prevAttr, currAttr) {
        const changes = {
            clefChanged: false,
            keyChanged: false,
            timeChanged: false
        };

        if (!prevAttr && currAttr) {
            // 모든 속성이 새로 추가됨
            changes.clefChanged = !!currAttr.clef;
            changes.keyChanged = !!currAttr.key;
            changes.timeChanged = !!currAttr.time;
            return changes;
        }
        if (!currAttr && prevAttr) {
            // 모든 속성이 제거됨
            changes.clefChanged = !!prevAttr.clef;
            changes.keyChanged = !!prevAttr.key;
            changes.timeChanged = !!prevAttr.time;
            return changes;
        }
        if (!prevAttr && !currAttr) {
            return changes;
        }

        // 클레프 변경 여부
        if (currAttr.clef && prevAttr.clef) {
            changes.clefChanged = JSON.stringify(prevAttr.clef) !== JSON.stringify(currAttr.clef);
        } else if (currAttr.clef || prevAttr.clef) {
            changes.clefChanged = true;
        }

        // 조표 변경 여부
        if (currAttr.key) {
            if (!prevAttr.key) {
                changes.keyChanged = true;
            } else {
                const prevKey = prevAttr.key.fifths + prevAttr.key.mode;
                const currKey = currAttr.key.fifths + currAttr.key.mode;
                changes.keyChanged = prevKey !== currKey;
            }
        }

        // 박자표 변경 여부
        if (currAttr.time) {
            if (!prevAttr.time) {
                changes.timeChanged = true;
            } else {
                changes.timeChanged = JSON.stringify(prevAttr.time) !== JSON.stringify(currAttr.time);
            }
        }

        return changes;
    }

    renderSelectedParts(scoreInfo, selectedParts, measuresToDraw, elementId, Vex, containerWidth, containerHeight, staveHeight, staveColor, customYOffsetMap = {}, attributeWidth) {
        const container = document.getElementById(elementId);
        if (!container) {
            console.error(`Element with id ${elementId} not found`);
            return;
        }

        container.innerHTML = '';

        const measuresPerLine = 4;
        const lines = Math.ceil(measuresToDraw.length / measuresPerLine);

        let totalHeight = 0;
        const spacingBetweenLinesPx = 10;

        selectedParts.forEach(partIndex => {
            totalHeight += this.calculatePartHeight(this.scorePartwise.parts[partIndex], spacingBetweenLinesPx) * lines;
        });

        totalHeight += selectedParts.length * 50;

        const scaleFactor = Math.min(containerWidth / 1600, (containerHeight * 0.4) / totalHeight);

        const scaledWidth = containerWidth / scaleFactor;
        const scaledHeight = totalHeight;

        const factory = new Vex.Flow.Factory({
            renderer: { elementId: elementId, width: containerWidth, height: containerHeight }
        });

        const context = factory.getContext();
        context.scale(scaleFactor, scaleFactor);

        // 수직 중앙 정렬 오프셋 계산
        const yOffset = Math.max(0, (containerHeight / scaleFactor - scaledHeight) / 2);

        let currentYOffset = yOffset;

        selectedParts.forEach((partIndex, index) => {
            const part = this.scorePartwise.parts[partIndex];

            // 이전 페이지의 조옮김 검사
            this.updateCurrentKeySignature(part, measuresToDraw[0]);

            // 초기 속성 추출
            const initialAttributes = part.measures[0].elements.find(element => element instanceof Attributes);
            const { isDoubleStave, isTabStave, staveLines } = this.extractInitialAttributes(initialAttributes);

            let partYOffset = currentYOffset;

            if (customYOffsetMap.hasOwnProperty(partIndex)) {
                partYOffset = customYOffsetMap[partIndex];
            }

            // 현재 속성 상태를 추적하기 위한 변수 추가
            let currentAttributes = {};
            if (initialAttributes) {
                currentAttributes = { ...initialAttributes };
            }

            // renderPart에 초기 속성 전달 및 현재 속성 상태 초기화
            this.renderPart(part, factory, context, partYOffset, measuresToDraw, Vex, scaleFactor, scaledWidth, staveHeight, staveColor, attributeWidth, spacingBetweenLinesPx, isDoubleStave, isTabStave, staveLines, currentAttributes);
            currentYOffset += (this.calculatePartHeight(part, spacingBetweenLinesPx) * lines + 50);
        });
    }

    // 초기 속성을 추출하는 헬퍼 함수 추가
    extractInitialAttributes(attributes) {
        if (!attributes) {
            return {
                isDoubleStave: false,
                isTabStave: false,
                staveLines: 5
            };
        }

        let isDoubleStave = false;
        let isTabStave = false;
        let staveLines = 5;

        if (attributes.staves === "2") {
            isDoubleStave = true;
        }

        if (attributes.clef && attributes.clef.length > 0) {
            isTabStave = attributes.clef.some(clef => clef.sign === 'TAB');
        }

        // staff-details에서 줄 수를 가져옴
        if (attributes.staffDetails && attributes.staffDetails.length > 0) {
            const staffDetail = attributes.staffDetails[0];
            if (staffDetail.staffLines) {
                staveLines = parseInt(staffDetail.staffLines, 10);
            }
        } else if (isTabStave) {
            staveLines = 6; // TAB 스태프의 기본 줄 수
        }

        return {
            isDoubleStave,
            isTabStave,
            staveLines
        };
    }

    calculatePartHeight(part, spacingBetweenLinesPx) {
        let { isDoubleStave, isTabStave, staveLines } = this.extractInitialAttributes(part.measures[0].elements.find(element => element instanceof Attributes));

        // spacingBetweenLinesPx를 사용하여 staveHeight 계산
        let staveHeight = (staveLines - 1) * spacingBetweenLinesPx;

        let additionalSpacing = 10;
        if (isDoubleStave) {
            additionalSpacing += 30;
        }
        if (isTabStave) {
            additionalSpacing += 30;
        }

        return staveHeight + additionalSpacing;
    }

    updateCurrentKeySignature(part, firstMeasureIndex) {
        for (let i = 0; i < firstMeasureIndex; i++) {
            const measure = part.measures[i];
            const keySignatureElement = measure.elements.find(el => el instanceof Attributes && el.key);
            if (keySignatureElement) {
                this.currentKeySignature = keySignatureElement.key;
            }
        }
    }

    renderPart(part, factory, context, yOffset, measuresToDraw, Vex, scaleFactor, adjustedContainerWidth, staveHeight, staveColor, attributeWidth, spacingBetweenLinesPx, isDoubleStave, isTabStave, staveLines, currentAttributes) {
        const { Formatter, Beam, Tuplet, Curve, GraceNote, GraceNoteGroup } = Vex.Flow;

        const staves = [];
        const stavesPerLine = 4;
        const totalLines = Math.ceil(measuresToDraw.length / stavesPerLine);

        let previousAttributes = null;

        const initialAttributes = part.measures[0].elements.find(element => element instanceof Attributes);
        if (initialAttributes) {
            previousAttributes = initialAttributes;
        }

        for (let line = 0; line < totalLines; line++) {
            // 슬러와 타이를 각 라인(line)마다 선언하여 스코프 문제 해결
            const openSlurs = {};
            const slursToDraw = [];

            const openTies = {};
            const tiesToDraw = [];

            // 시스템의 첫 번째 마디와 마지막 마디 인덱스 계산
            const firstMeasureIndex = measuresToDraw[line * stavesPerLine];
            const lastMeasureIndex = measuresToDraw[Math.min((line + 1) * stavesPerLine - 1, measuresToDraw.length - 1)];

            for (let staveIndex = 0; staveIndex < stavesPerLine; staveIndex++) {
                const measureIndex = measuresToDraw[line * stavesPerLine + staveIndex];
                if (measureIndex === undefined || measureIndex >= part.measures.length) break;

                const measure = part.measures[measureIndex];
                let attributesElement = measure.elements.find(element => element instanceof Attributes);

                // 이전 속성과 현재 속성을 병합
                const mergedAttributes = this.mergeAttributes(previousAttributes, attributesElement);

                // 속성 변경 여부 확인
                const attributesChanges = this.compareAttributes(previousAttributes, mergedAttributes);
                const attributesChanged = attributesChanges.clefChanged || attributesChanges.keyChanged || attributesChanges.timeChanged;

                // 이전 속성 업데이트
                previousAttributes = mergedAttributes;

                // 현재 속성 상태 업데이트
                if (attributesChanged) {
                    currentAttributes = { ...mergedAttributes };
                }
                // 스태프 관련 설정은 초기 값으로 고정
                let isPercussionStave = this.isPercussionStave(mergedAttributes); // 수정
                let currentStaveLines = staveLines;

                if (mergedAttributes && mergedAttributes.clef && mergedAttributes.clef.length > 0) {
                    isPercussionStave = mergedAttributes.clef.some(clef => clef.sign === 'percussion') || isPercussionStave; // 수정
                }

                // 스태프 생성 및 위치 계산
                const spacing = isDoubleStave ? staveHeight * 0.2 : 0;
                const staveY = yOffset + (line * spacingBetweenLinesPx * (staveLines - 1)) + (line * 50);

                let xPosition;
                let currentStaveWidth;

                const staveWidth = ((adjustedContainerWidth - 178) / stavesPerLine) + 3.5;

                if (staveIndex === 0) {
                    xPosition = 0;
                    currentStaveWidth = staveWidth + 178;
                } else {
                    xPosition = 178 + staveWidth * (staveIndex);
                    currentStaveWidth = staveWidth;
                }

                // 마지막 마디인 경우 폭을 줄여서 여백을 만듭니다.
                if (staveIndex === stavesPerLine - 1) {
                    currentStaveWidth -= 15; // 15px 만큼 폭을 줄입니다.
                }

                let currentStave, currentStave2;
                if (isDoubleStave) {
                    const doubleStaveYOffset = 40;
                    currentStave = factory.Stave({
                        x: xPosition,
                        y: staveY - doubleStaveYOffset,
                        width: currentStaveWidth,
                        options: {
                            num_lines: staveLines,
                            spacing_between_lines_px: spacingBetweenLinesPx
                        }
                    });
                    currentStave2 = factory.Stave({
                        x: xPosition,
                        y: staveY + staveHeight + spacing - doubleStaveYOffset,
                        width: currentStaveWidth,
                        options: {
                            num_lines: staveLines,
                            spacing_between_lines_px: spacingBetweenLinesPx
                        }
                    });
                } else if (isTabStave) {
                    currentStave = factory.TabStave({
                        x: xPosition,
                        y: staveY,
                        width: currentStaveWidth,
                        options: {
                            num_lines: staveLines,
                            spacing_between_lines_px: spacingBetweenLinesPx + 2
                        }
                    });
                } else {
                    currentStave = factory.Stave({
                        x: xPosition,
                        y: staveY,
                        width: currentStaveWidth,
                        options: {
                            num_lines: staveLines,
                            spacing_between_lines_px: spacingBetweenLinesPx
                        }
                    });
                }

                currentStave.setStyle({
                    fillStyle: staveColor,
                    strokeStyle: staveColor
                });

                if (isDoubleStave) {
                    currentStave2.setStyle({
                        fillStyle: staveColor,
                        strokeStyle: staveColor
                    });
                }

                if (part === this.scorePartwise.parts[0]) {
                    context.save();
                    context.setFont('Arial', 7 / scaleFactor, '');
                    const measureNumber = '' + (measureIndex + 1);
                    const x = currentStave.getX() + 3;
                    const y = currentStave.getYForTopText(0) - 1 / scaleFactor;
                    context.fillText(measureNumber, x, y);
                    context.restore();
                }

                const isFirstMeasureOnSystem = staveIndex === 0;

                // 음표 그룹을 속성 처리 전에 선언
                const voiceGroups = isDoubleStave ? [{}, {}] : [{}];

                // 속성 렌더링
                if (isFirstMeasureOnSystem) {
                    // 시스템의 첫 번째 마디인 경우 현재 속성을 모두 렌더링
                    if (attributesChanged) {
                        this.renderCurrentAttributes(currentAttributes, currentStave, currentStave2, isDoubleStave, isTabStave, isPercussionStave); // 수정
                    } else {
                        this.renderCurrentAttributes(currentAttributes, currentStave, currentStave2, isDoubleStave, isTabStave, isPercussionStave); // 수정
                    }
                } else if (attributesChanged) {
                    // 첫 번째 마디가 아니지만 속성이 변경된 경우
                    this.renderChangedAttributes(attributesChanges, mergedAttributes, currentAttributes, currentStave, currentStave2, isDoubleStave, isTabStave, isPercussionStave, voiceGroups); // 수정
                }

                // 첫 번째 마디인 경우, 음표 시작 위치를 attributeWidth로 설정
                if (isFirstMeasureOnSystem) {
                    currentStave.setNoteStartX(currentStave.getX() + 178);
                    if (isDoubleStave && currentStave2) {
                        currentStave2.setNoteStartX(currentStave2.getX() + 178);
                    }
                }

                currentStave.setContext(context).draw();
                if (isDoubleStave && currentStave2) {
                    currentStave2.setContext(context).draw();
                    staves.push([currentStave, currentStave2]);
                } else {
                    staves.push(currentStave);
                }

                const beams = [];
                const tuplets = [];

                const harmonies = [];
                const lyrics = [];

                let currentBeamGroup = [];
                let isInBeamGroup = false;
                let currentTuplet = null;

                // 시간 추적을 위한 tick 변수
                let tick = 0;

                let graceNotes = [];
                let graceTabNotes = [];

                measure.elements.forEach((element, index) => {
                    switch (true) {
                        case element instanceof Attributes:
                            // 이미 속성 처리는 위에서 했으므로 여기서는 무시하거나 필요한 경우 추가 처리를 합니다.
                            break;

                        case element instanceof Note:
                            // <note print-object="no">인지 확인
                            if (!this.shouldPrint(element)) {
                                // 해당 음표를 렌더링하지 않음
                                if (this.shouldPrintSpacing(element)) {
                                    // 공간은 차지하므로 GhostNote 추가
                                    const duration = this.mapDuration(element.type);
                                    const ghostNote = new Vex.Flow.GhostNote({
                                        duration: duration
                                    });
                                    const staveIdx = isDoubleStave ? (parseInt(element.staff || '1') - 1) : 0;
                                    const voiceNumber = element.voice || '1';

                                    // 스태프 설정
                                    const currentStaveToUse = isDoubleStave ? (staveIdx === 0 ? currentStave : currentStave2) : currentStave;

                                    if (!voiceGroups[staveIdx][voiceNumber]) {
                                        voiceGroups[staveIdx][voiceNumber] = [];
                                    }
                                    voiceGroups[staveIdx][voiceNumber].push(ghostNote);
                                }
                                return;
                            }

                            const staveIdxNote = isDoubleStave ? (parseInt(element.staff || '1') - 1) : 0;
                            const voiceNumberNote = element.voice || '1';

                            if (!voiceGroups[staveIdxNote][voiceNumberNote]) {
                                voiceGroups[staveIdxNote][voiceNumberNote] = [];
                            }

                            // 스태프 설정
                            const currentStaveToUseNote = isDoubleStave ? (staveIdxNote === 0 ? currentStave : currentStave2) : currentStave;

                            if (element.grace) {
                                // 장식음 처리
                                const graceNote = this.createGraceNote(element, isTabStave, isPercussionStave); // 수정
                                if (graceNote) {
                                    graceNote.setStave(currentStaveToUseNote);

                                    if ((graceNote instanceof Vex.Flow.TabNote) || (graceNote instanceof Vex.Flow.StaveNote)) {
                                        voiceGroups[staveIdxNote][voiceNumberNote].push(graceNote);
                                    } else {
                                        if (isTabStave) {
                                            graceTabNotes.push(graceNote);
                                        } else if (isPercussionStave) { // 수정
                                            graceNotes.push(graceNote);
                                        } else {
                                            graceNotes.push(graceNote);
                                        }
                                    }
                                }
                            } else {
                                // 일반 음표 처리
                                let vexNote;
                                if (isTabStave) {
                                    vexNote = element.getVexFlowTabNote(factory);
                                } else if (element.unpitched) {
                                    vexNote = element.getVexFlowPercussionNote(factory);
                                } else {
                                    vexNote = element.getVexFlowStaveNote(factory);
                                }

                                vexNote.setStave(currentStaveToUseNote);

                                // GraceNoteGroup 추가
                                if (isTabStave && graceTabNotes.length > 0) {
                                    try {
                                        const graceNoteGroup = new Vex.Flow.GraceNoteGroup(graceTabNotes, true);
                                        vexNote.addModifier(graceNoteGroup, 0);
                                        graceTabNotes = [];
                                    } catch (error) {
                                        console.error('GraceNoteGroup 생성 중 오류:', error);
                                    }
                                } else if (isPercussionStave && graceNotes.length > 0) { // 수정
                                    try {
                                        const graceNoteGroup = new Vex.Flow.GraceNoteGroup(graceNotes, true);
                                        vexNote.addModifier(graceNoteGroup, 0);
                                        graceNotes = [];
                                    } catch (error) {
                                        console.error('GraceNoteGroup 생성 중 오류:', error);
                                    }
                                } else if (!isTabStave && graceNotes.length > 0) {
                                    try {
                                        const graceNoteGroup = new Vex.Flow.GraceNoteGroup(graceNotes, true);
                                        vexNote.addModifier(graceNoteGroup, 0);
                                        graceNotes = [];
                                    } catch (error) {
                                        console.error('GraceNoteGroup 생성 중 오류:', error);
                                    }
                                }

                                element.applyTechnical(vexNote, isTabStave);

                                voiceGroups[staveIdxNote][voiceNumberNote].push(vexNote);

                                // 하모니 처리
                                if (harmonies.length > 0 && harmonies[harmonies.length - 1].note === null) {
                                    if (vexNote.duration !== 'w') {
                                        harmonies[harmonies.length - 1].note = vexNote;
                                    }
                                }

                                // 가사 처리
                                if (element.lyrics && element.lyrics.length > 0) {
                                    element.lyrics.forEach((lyric, lyricIndex) => {
                                        if (lyric.text) {
                                            lyrics.push({
                                                text: lyric.text,
                                                note: vexNote,
                                                index: lyricIndex
                                            });
                                        }
                                    });
                                }

                                // 빔 처리
                                if (element.beams && element.beams.length > 0) {
                                    const beam = element.beams.find(b => b.number === '1');
                                    if (beam) {
                                        switch (beam.value) {
                                            case 'begin':
                                                isInBeamGroup = true;
                                                currentBeamGroup = [vexNote];
                                                break;
                                            case 'end':
                                                if (isInBeamGroup) {
                                                    currentBeamGroup.push(vexNote);
                                                    if (currentBeamGroup.length >= 2) {
                                                        beams.push(new Beam(currentBeamGroup));
                                                    }
                                                    isInBeamGroup = false;
                                                    currentBeamGroup = [];
                                                }
                                                break;
                                            case 'continue':
                                                if (isInBeamGroup) {
                                                    currentBeamGroup.push(vexNote);
                                                }
                                                break;
                                        }
                                    }
                                } else {
                                    if (isInBeamGroup) {
                                        currentBeamGroup.push(vexNote);
                                    }
                                }

                                // 투플렛 처리
                                if (element.timeModification) {
                                    const actualNotes = parseInt(element.timeModification.actualNotes);
                                    const normalNotes = parseInt(element.timeModification.normalNotes);

                                    if (!currentTuplet) {
                                        currentTuplet = {
                                            notes: [],
                                            actualNotes: actualNotes,
                                            normalNotes: normalNotes
                                        };
                                    }

                                    currentTuplet.notes.push(vexNote);

                                    if (currentTuplet.notes.length === currentTuplet.actualNotes) {
                                        const tupletOptions = {
                                            num_notes: currentTuplet.actualNotes,
                                            notes_occupied: currentTuplet.normalNotes
                                        };
                                        tuplets.push(new Tuplet(currentTuplet.notes, tupletOptions));
                                        currentTuplet = null;
                                    }
                                } else if (currentTuplet) {
                                    console.log(`Warning: Incomplete tuplet found (${currentTuplet.notes.length}/${currentTuplet.actualNotes})`);
                                    currentTuplet = null;
                                }

                                // 타이 처리
                                if (element.ties && element.ties.length > 0) {
                                    element.ties.forEach(tie => {
                                        const tieNumber = tie.number || 1;
                                        const tieType = tie.type;

                                        if (tieType === 'start') {
                                            if (!openTies[staveIdxNote]) {
                                                openTies[staveIdxNote] = {};
                                            }
                                            openTies[staveIdxNote][tieNumber] = {
                                                startNote: vexNote,
                                                staveIdx: staveIdxNote,
                                                startIndex: index // 노트의 인덱스 저장
                                            };
                                        } else if (tieType === 'stop') {
                                            if (openTies[staveIdxNote] && openTies[staveIdxNote][tieNumber]) {
                                                const tieInfo = openTies[staveIdxNote][tieNumber];
                                                tiesToDraw.push({
                                                    startNote: tieInfo.startNote,
                                                    endNote: vexNote,
                                                    staveIdx: staveIdxNote,
                                                    startIndex: tieInfo.startIndex, // 시작 인덱스 저장
                                                    endIndex: index // 종료 인덱스 저장
                                                });
                                                delete openTies[staveIdxNote][tieNumber];
                                            } else {
                                                // 시작점이 없는 경우
                                                tiesToDraw.push({
                                                    startNote: null,
                                                    endNote: vexNote,
                                                    staveIdx: staveIdxNote,
                                                    startIndex: null,
                                                    endIndex: index // 종료 인덱스 저장
                                                });
                                            }
                                        }
                                    });
                                }


                                // 슬러 처리
                                if (element.notations && element.notations.slur) {
                                    element.notations.slur.forEach(slur => {
                                        const slurNumber = slur.number || 1;
                                        const slurType = slur.type;

                                        if (slurType === 'start') {
                                            openSlurs[slurNumber] = {
                                                startNote: vexNote,
                                                staveIdx: staveIdxNote,
                                                startSlurData: slur, // 시작 슬러 데이터 추가
                                                startIndex: index // 노트의 인덱스 저장
                                            };
                                        } else if (slurType === 'stop') {
                                            if (openSlurs[slurNumber]) {
                                                const slurInfo = openSlurs[slurNumber];
                                                const slurData = {
                                                    startNote: slurInfo.startNote,
                                                    endNote: vexNote,
                                                    first_indices: [0],
                                                    last_indices: [0],
                                                    staveIdx: staveIdxNote,
                                                    startSlurData: slurInfo.startSlurData,
                                                    stopSlurData: slur,
                                                    startIndex: slurInfo.startIndex, // 시작 인덱스 저장
                                                    endIndex: index // 종료 인덱스 저장
                                                };
                                                slursToDraw.push(slurData);
                                                delete openSlurs[slurNumber];
                                            } else {
                                                // 시작점이 없는 경우
                                                slursToDraw.push({
                                                    startNote: null,
                                                    endNote: vexNote,
                                                    staveIdx: staveIdxNote,
                                                    startSlurData: null,
                                                    stopSlurData: slur,
                                                    startIndex: null,
                                                    endIndex: index // 종료 인덱스 저장
                                                });
                                            }
                                        }
                                    });
                                }

                            }
                            break;

                        case element instanceof Harmony:
                            harmonies.push({
                                note: null,
                                harmony: element
                            });
                            break;

                        case element instanceof Backup:
                            tick -= parseInt(element.duration || 0);
                            break;

                        case element instanceof Forward:
                            tick += parseInt(element.duration || 0);
                            break;
                    }
                });

                // 열린 빔 그룹 처리
                if (isInBeamGroup && currentBeamGroup.length >= 2) {
                    beams.push(new Beam(currentBeamGroup));
                    isInBeamGroup = false;
                    currentBeamGroup = [];
                }

                // GraceNote 처리되지 않은 경우
                if (graceNotes.length > 0 || graceTabNotes.length > 0) {
                    console.warn('마지막 음표 이후에 처리되지 않은 grace note가 있습니다.');
                    graceNotes = [];
                    graceTabNotes = [];
                }

                // 열린 슬러와 타이를 처리하여 시스템의 마지막에 부분 타이를 그립니다
                if (measureIndex === lastMeasureIndex) {
                    // 열린 타이 처리
                    Object.keys(openTies).forEach(staveIdx => {
                        Object.keys(openTies[staveIdx]).forEach(tieNumber => {
                            const tieInfo = openTies[staveIdx][tieNumber];
                            // 시스템의 마지막에서 부분 타이 그리기
                            tiesToDraw.push({
                                startNote: tieInfo.startNote,
                                endNote: null, // 다음 시스템으로 이어지는 부분 타이
                                staveIdx: staveIdx
                            });
                            // 글로벌 상태에 저장하지 않고 현재에서 처리
                            delete openTies[staveIdx][tieNumber];
                        });
                    });

                    // 수정: 열린 슬러 처리 추가
                    Object.keys(openSlurs).forEach(slurNumber => { // 수정: 열린 슬러 처리 추가
                        const slurInfo = openSlurs[slurNumber];
                        // 시스템의 마지막에서 부분 슬러 그리기
                        slursToDraw.push({
                            startNote: slurInfo.startNote,
                            endNote: null,
                            staveIdx: slurInfo.staveIdx,
                            startSlurData: slurInfo.startSlurData,
                            stopSlurData: null,
                            startIndex: slurInfo.startIndex, // 수정
                            endIndex: null // 수정
                        });
                        // 글로벌 상태에 저장하지 않고 현재에서 처리
                        delete openSlurs[slurNumber];
                    }); // 수정: 열린 슬러 처리 추가
                }

                const formatter = new Formatter({ align_rests: true, softmaxFactor: 80 });

                voiceGroups.forEach((staveVoices, staveIdx) => {
                    const voiceCount = Object.keys(staveVoices).length;
                    if (voiceCount > 0) {
                        const vexVoices = Object.values(staveVoices).map(notes => {
                            const voice = factory.Voice({ num_beats: 4, beat_value: 4 }).setStrict(false);

                            // 스태프 설정
                            const currentStaveToUseVoice = isDoubleStave ? (staveIdx === 0 ? currentStave : currentStave2) : currentStave;
                            voice.setStave(currentStaveToUseVoice);

                            voice.addTickables(notes);
                            return voice;
                        });

                        const currentStaveToUseVoice = isDoubleStave ? (staveIdx === 0 ? currentStave : currentStave2) : currentStave;
                        const modifierXShift = typeof currentStaveToUseVoice.getModifierXShift === 'function' ? currentStaveToUseVoice.getModifierXShift() : 0;

                        let formatWidth = currentStaveWidth - modifierXShift - 10;

                        if (staveIndex === stavesPerLine - 1) {
                            // 마지막 마디인 경우 15px 만큼 폭을 줄여서 여백을 만듭니다.
                            formatWidth -= 15;
                        }

                        formatter.joinVoices(vexVoices).format(vexVoices, formatWidth);

                        vexVoices.forEach(voice => voice.draw(context, currentStaveToUseVoice));
                    }
                });

                // 빔과 투플렛 그리기
                beams.forEach(beam => {
                    try {
                        beam.setContext(context).draw();
                    } catch (error) {
                        console.error('Beam 그리는 중 오류:', error);
                    }
                });

                tuplets.forEach(tuplet => {
                    try {
                        tuplet.setContext(context).draw();
                    } catch (error) {
                        console.error('Tuplet 그리는 중 오류:', error);
                    }
                });

                // 타이 그리기
                tiesToDraw.forEach(tie => {
                    if (isTabStave) {
                        return;
                    }
                    try {
                        if (tie.startNote && tie.endNote) {
                            const firstIndices = tie.startNote.getKeys().map((_, idx) => idx);
                            const lastIndices = tie.endNote.getKeys().map((_, idx) => idx);

                            new Vex.Flow.StaveTie({
                                first_note: tie.startNote,
                                last_note: tie.endNote,
                                first_indices: firstIndices,
                                last_indices: lastIndices,
                            }).setContext(context).draw();
                        } else {
                            // 오픈 타이 처리
                            const note = tie.startNote || tie.endNote;
                            const isStart = !!tie.startNote;

                            const stemDirection = note.getStemDirection();

                            const curve = new Vex.Flow.Curve(
                                isStart ? note : null,
                                isStart ? null : note,
                                {
                                    invert: false,
                                    position: Vex.Flow.Curve.Position.NEAR_HEAD,
                                    y_shift_start: 0,
                                    y_shift_end: 0,
                                    x_shift_start: 0,
                                    x_shift_end: 0,
                                    color: 'black'
                                }
                            );

                            curve.setContext(context).draw();
                        }
                    } catch (error) {
                        console.error('Error drawing tie:', error);
                    }
                });

                // 슬러 그리기
                // 슬러를 그립니다.

                slursToDraw.forEach(slur => {
                    try {
                        const currentStaveToUse = isDoubleStave ? (slur.staveIdx === 0 ? currentStave : currentStave2) : currentStave;


                        const startStemDirection = slur.startNote.getStemDirection();
                        const endStemDirection = slur.endNote.getStemDirection();

                        const yShift = 10;
                        const xShiftStart = -5;
                        const xShiftEnd = 5;

                        let startYShift = startStemDirection === Vex.Flow.Stem.UP ? yShift : -yShift;
                        let endYShift = endStemDirection === Vex.Flow.Stem.UP ? yShift : -yShift;

                        // bezier-y 값을 가져오고 stem 방향에 따라 부호를 조정합니다.
                        let bezierY = slur.startSlurData && slur.startSlurData.bezierY ? parseInt(slur.startSlurData.bezierY) : 20;
                        bezierY = startStemDirection === Vex.Flow.Stem.UP ? -bezierY : bezierY;

                        const
                            slurCurve = new Curve(slur.startNote, slur.endNote,
                                {
                                    cps: [
                                        {
                                            x: 0, y: bezierY
                                        },
                                        {
                                            x: 0, y: bezierY
                                        }
                                    ],
                                    invert: false,
                                    position: Vex.Flow.Curve.Position.NEAR_HEAD,
                                    position_end: Vex.Flow.Curve.Position.NEAR_HEAD,
                                    y_shift_start: startYShift,
                                    y_shift_end: endYShift,
                                    x_shift_start: xShiftStart,
                                    x_shift_end: xShiftEnd,

                                    thickness: 2,
                                    color: 'black'
                                });

                        slurCurve.setContext(context).draw();

                    } catch (error) {
                        console.error('Slur 그리는 중 오류:', error);
                    }
                });



                const harmoniesWithNotes = harmonies.filter(harmony => harmony.note);
                const harmoniesWithoutNotes = harmonies.filter(harmony => !harmony.note);

                harmoniesWithNotes.forEach(harmony => {
                    const noteBB = harmony.note.getBoundingBox();
                    let textX, textY;

                    if (noteBB) {
                        const noteX = noteBB.getX();
                        const noteWidth = noteBB.getW();
                        textX = noteX + noteWidth / 2;
                        textY = currentStave.getYForTopText(0) - 40;
                    } else {
                        textX = currentStave.getX() + currentStave.getWidth() / 2;
                        textY = currentStave.getYForTopText(0) - 5;
                    }

                    context.save();
                    context.fillStyle = 'black';
                    context.textAlign = 'center';
                    context.setFont('Arial', 10 / scaleFactor, '');
                    if (typeof harmony.harmony.generateHarmonyText === 'function') {
                        context.fillText(harmony.harmony.generateHarmonyText(), textX, textY);
                    } else {
                        console.warn('harmony.harmony.generateHarmonyText 함수가 존재하지 않습니다:', harmony.harmony);
                        context.fillText('Unknown Harmony', textX, textY);
                    }
                    context.restore();
                });

                if (harmoniesWithoutNotes.length > 0) {
                    const staveStartX = currentStave.getNoteStartX();
                    const staveEndX = currentStave.getX() + currentStave.getWidth();
                    const availableWidth = staveEndX - staveStartX;

                    harmoniesWithoutNotes.forEach((harmony, index) => {
                        const totalHarmonies = harmoniesWithoutNotes.length;
                        let textX;

                        if (totalHarmonies === 1) {
                            textX = staveStartX + availableWidth / 2;
                        } else {
                            const segment = availableWidth / totalHarmonies;
                            textX = staveStartX + (segment * index) + segment / 2;
                        }

                        const textY = currentStave.getYForTopText(0) - 5;

                        context.save();
                        context.fillStyle = 'black';
                        context.textAlign = 'center';
                        context.setFont('Arial', 12 / scaleFactor, '');
                        context.fillText(harmony.harmony.generateHarmonyText(), textX, textY);
                        context.restore();
                    });
                }

                lyrics.forEach(lyric => {
                    const noteBB = lyric.note.getBoundingBox();
                    let textX, textY;

                    if (noteBB) {
                        const noteX = noteBB.getX();
                        textX = noteX;
                        textY = currentStave.getYForBottomText(0) + 30 + ((lyric.index) * 15);
                    } else {
                        textX = currentStave.getX() + currentStave.getWidth() / 2;
                        textY = currentStave.getYForBottomText(0) + ((lyric.index) * 15);
                    }

                    context.save();
                    context.fillStyle = 'black';
                    context.textAlign = 'center';

                    const baseFontSize = '1.5em';

                    // viewport 크기에 따라 폰트 크기 조정
                    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                    const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                    const adjustedFontSize = `${parseFloat(baseFontSize)}em`;

                    context.font = `normal ${adjustedFontSize} Times`;
                    context.fillText(lyric.text, textX, textY);
                    context.restore();
                });
            }
        }
    }

    renderCurrentAttributes(currentAttributes, currentStave, currentStave2, isDoubleStave, isTabStave, isPercussionStave) {
        if (isTabStave) {
            currentStave.addTabGlyph();
        } else if (currentAttributes.clef && currentAttributes.clef.length > 0) {
            currentAttributes.clef.forEach((clef, idx) => {
                let vexClef = isPercussionStave ? 'percussion' : clef.getVexFlowClef();

                const clefModifier = new Vex.Flow.Clef(vexClef);

                if (idx === 0 || !isDoubleStave) {
                    currentStave.addModifier(clefModifier);
                } else if (isDoubleStave && currentStave2) {
                    currentStave2.addModifier(clefModifier);
                }
            });
        }

        // Key signature rendering (skip on TAB staves)
        if (!isTabStave && !isPercussionStave) { // 수정
            const keyToRender = this.currentKeySignature || currentAttributes.key;
            if (keyToRender) {
                const vexKey = keyToRender.getVexFlowKeySignature();
                if (vexKey !== undefined) {
                    currentStave.addKeySignature(vexKey);
                    if (isDoubleStave && currentStave2) {
                        currentStave2.addKeySignature(vexKey);
                    }
                } else {
                    console.warn('VexFlow key signature is undefined');
                }
            }
        }

        // Time signature rendering (skip on TAB staves)
        if (!isTabStave && currentAttributes.time) { // 수정
            const timeSignature = `${currentAttributes.time.beats}/${currentAttributes.time.beatType}`;
            currentStave.addTimeSignature(timeSignature);
            if (isDoubleStave && currentStave2) currentStave2.addTimeSignature(timeSignature);
        }
    }

    renderChangedAttributes(changes, mergedAttributes, currentAttributes, currentStave, currentStave2, isDoubleStave, isTabStave, isPercussionStave, voiceGroups) {
        if (changes.clefChanged) {
            currentAttributes.clef = mergedAttributes.clef;
            if (mergedAttributes.clef) {
                mergedAttributes.clef.forEach((clef, idx) => {
                    let vexClef = isPercussionStave ? 'percussion' : clef.getVexFlowClef();

                    const clefNote = new Vex.Flow.ClefNote(vexClef);
                    const staveIdx = isDoubleStave ? idx : 0;

                    const currentStaveToUse = isDoubleStave ? (staveIdx === 0 ? currentStave : currentStave2) : currentStave;
                    clefNote.setStave(currentStaveToUse);

                    const voiceNumber = '1';
                    if (!voiceGroups[staveIdx][voiceNumber]) {
                        voiceGroups[staveIdx][voiceNumber] = [];
                    }
                    voiceGroups[staveIdx][voiceNumber].push(clefNote);
                });
            }
        }

        // Key signature change handling (skip on TAB staves)
        if (!isTabStave && changes.keyChanged && mergedAttributes.key && !isPercussionStave) { // 수정
            currentAttributes.key = mergedAttributes.key;
            this.currentKeySignature = mergedAttributes.key; // Update current key signature

            const vexKey = mergedAttributes.key.getVexFlowKeySignature();

            if (vexKey !== undefined) {
                const keySigNote = new Vex.Flow.KeySigNote(vexKey);
                keySigNote.setStave(currentStave);

                const voiceNumber = '1';
                if (!voiceGroups[0][voiceNumber]) {
                    voiceGroups[0][voiceNumber] = [];
                }
                voiceGroups[0][voiceNumber].push(keySigNote);

                if (isDoubleStave && currentStave2) {
                    const keySigNote2 = new Vex.Flow.KeySigNote(vexKey);
                    keySigNote2.setStave(currentStave2);
                    voiceGroups[1][voiceNumber] = voiceGroups[1][voiceNumber] || [];
                    voiceGroups[1][voiceNumber].push(keySigNote2);
                }
            } else {
                console.warn('VexFlow key signature is undefined');
            }
        }

        // Time signature change handling (skip on TAB staves)
        if (!isTabStave && changes.timeChanged && mergedAttributes.time) { // 수정
            currentAttributes.time = mergedAttributes.time;

            const timeSigNote = new Vex.Flow.TimeSigNote(`${mergedAttributes.time.beats}/${mergedAttributes.time.beatType}`);
            timeSigNote.setStave(currentStave);

            const voiceNumber = '1';
            if (!voiceGroups[0][voiceNumber]) {
                voiceGroups[0][voiceNumber] = [];
            }
            voiceGroups[0][voiceNumber].push(timeSigNote);

            if (isDoubleStave && currentStave2) {
                const timeSigNote2 = new Vex.Flow.TimeSigNote(`${mergedAttributes.time.beats}/${mergedAttributes.time.beatType}`);
                timeSigNote2.setStave(currentStave2);
                voiceGroups[1][voiceNumber] = voiceGroups[1][voiceNumber] || [];
                voiceGroups[1][voiceNumber].push(timeSigNote2);
            }
        }
    }

    // 타악기 스태프인지 확인하는 헬퍼 함수 추가
    isPercussionStave(attributes) { // 수정
        if (!attributes || !attributes.clef) return false;
        return attributes.clef.some(clef => clef.sign === 'percussion');
    }

    createGraceNote(element, isTabStave, isPercussionStave) { // 수정
        if (!element.grace) return null;

        const duration = this.mapDuration(element.type);

        if (isTabStave) {
            // GraceTabNote 생성
            const graceNoteStruct = {
                duration: duration,
                positions: element.getTabPositions(),
                slash: element.grace.slash === 'yes'
            };
            const graceNote = new Vex.Flow.GraceTabNote(graceNoteStruct);
            element.applyTechnical(graceNote, isTabStave);
            return graceNote;
        } else if (isPercussionStave) { // 수정
            // GraceNote for unpitched (percussion) staves
            const graceNoteStruct = {
                duration: duration,
                keys: element.getVexFlowKeys() || ['C/5'], // 기본 키 설정 또는 요소에서 키 가져오기
                unpitched: true, // unpitched 설정
                slash: element.grace.slash === 'yes'
            };
            const graceNote = new Vex.Flow.GraceNote(graceNoteStruct); // GraceNote 사용
            element.applyTechnical(graceNote, isTabStave);
            return graceNote;
        } else {
            // 일반 GraceNote 처리
            const graceNoteStruct = {
                duration: duration,
                keys: element.getVexFlowKeys(),
                slash: element.grace.slash === 'yes'
            };
            const graceNote = new Vex.Flow.GraceNote(graceNoteStruct);
            element.applyTechnical(graceNote, isTabStave);
            return graceNote;
        }
    }

    renderMeasure(container, Vex, containerWidth, part, measure, measureIndex) {
        const factory = new Vex.Flow.Factory({
            renderer: { elementId: container, width: containerWidth, height: 150 }
        });

        const context = factory.getContext();
        const stave = factory.Stave({ x: 0, y: 0, width: (containerWidth + 2) });

        if (measureIndex === 0 || measure.attributes) {
            if (measure.attributes.clef && measure.attributes.clef.length > 0) {
                stave.addClef(measure.attributes.clef[0].getVexFlowClef());
            }
            // <key print-object="no">인지 확인
            const shouldPrintKeySignature = !(measure.attributes.key && !measure.attributes.key.printObject);
            if (measure.attributes.key && shouldPrintKeySignature) {
                stave.addKeySignature(measure.attributes.key.getVexFlowKeySignature());
            }
            if (measure.attributes.time) {
                stave.addTimeSignature(`${measure.attributes.time.beats}/${measure.attributes.time.beatType}`);
            }
        }

        stave.setContext(context).draw();

        // <note print-object="no">인지 확인하여 필터링
        const notes = measure.elements.filter(el => el instanceof Note && this.shouldPrint(el)).map(note => {
            const vexNote = note.getVexFlowStaveNote(factory);
            // If duration is 'w', change it to 'h'
            if (vexNote.duration === 'w') {
                vexNote.duration = 'h';
            }
            return vexNote;
        });

        // Get time information
        const timeSignature = measure.attributes?.time || { beats: 4, beatType: 4 };

        const voice = factory.Voice({
            num_beats: parseInt(timeSignature.beats),
            beat_value: parseInt(timeSignature.beatType),
        }).setStrict(false);  // Added setStrict(false) for flexibility

        try {
            voice.addTickables(notes);
        } catch (error) {
            console.error('Tickables 추가 중 오류:', error);
            console.log('문제가 있는 measure:', measure);
            console.log('Notes:', notes);
            return;  // 오류 발생 시 렌더링 중단
        }

        // Formatter 간격 줄이기
        new Vex.Flow.Formatter().joinVoices([voice]).format([voice], containerWidth - 60); // 간격 줄이기
        voice.draw(context, stave);
    }
}

