// src/store/room.js
import { defineStore } from 'pinia';
import {
    ref,
    onValue,
    update,
    push,
    set,
    remove,
    get,
    query,
    orderByChild,
    equalTo
} from 'firebase/database';
import { rtdb } from '../firebase';
import { useAuthStore } from './auth';
import { useMetronomeStore } from '@/store/metronome';
import { trackJoinRoom, trackCreateRoom } from '@/analytics';

export const useRoomStore = defineStore('room', {
    state: () => ({
        rooms: {},
        currentRoom: null,
        isInRoom: false,
        currentBeat: 1,
        currentAlbumId: null,
        currentRoomData: null, // 상태 속성 유지
        roomListener: null, // **수정: 리스너를 저장할 상태 추가**
    }),
    actions: {
        async fetchRooms() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            console.log('Fetching rooms...');
            const roomsRef = ref(rtdb, 'rooms');
            onValue(roomsRef, (snapshot) => {
                const allRooms = snapshot.val();
                console.log('All rooms:', JSON.parse(JSON.stringify(allRooms)));
                if (allRooms) {
                    this.rooms = allRooms;
                    console.log('Fetched rooms:', JSON.parse(JSON.stringify(this.rooms)));
                } else {
                    this.rooms = {};
                    console.log('No rooms found');
                }
            }, (error) => {
                console.error('Error fetching rooms:', error);
            });
        },

        async fetchCurrentRoom(roomId) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            console.log('Fetching current room:', roomId);
            if (!roomId) {
                console.error('No roomId provided to fetchCurrentRoom');
                return;
            }
            const roomRef = ref(rtdb, `rooms/${roomId}`);
            try {
                const snapshot = await get(roomRef);
                if (snapshot.exists()) {
                    const roomData = snapshot.val();
                    if (roomData.isPersonal && roomData.createdBy !== authStore.user.uid) {
                        console.error('No access to this personal room');
                        return;
                    }
                    this.rooms = { ...this.rooms, [roomId]: roomData };
                    this.currentRoom = roomId;
                    this.isInRoom = true;
                    this.currentRoomData = roomData;
                    localStorage.setItem('currentRoomId', roomId);
                } else {
                    console.log('Room not found:', roomId);
                    this.currentRoom = null;
                    this.isInRoom = false;
                    this.currentRoomData = null;
                }
            } catch (error) {
                console.error('Error fetching current room:', error);
            }
        },

        async joinRoom(roomId) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            // **수정: 기존 리스너가 있으면 해제**
            if (this.roomListener) {
                this.roomListener();
                this.roomListener = null;
            }


            await authStore.fetchUserProfile();
            const nickname = authStore.userProfile?.nickname || '익명';

            const roomRef = ref(rtdb, `rooms/${roomId}`);
            const userInfo = {
                nickname: nickname,
                joinedAt: new Date().toISOString(),
                lastPing: new Date().toISOString(),
            };

            await update(roomRef, {
                [`participants/${authStore.user.uid}`]: userInfo,
            });

            this.currentRoom = roomId;
            this.isInRoom = true;
            //로그기록
            trackJoinRoom(roomId);
            localStorage.setItem('currentRoomId', roomId);
            await this.fetchCurrentRoom(roomId);
            this.listenToRoomChanges();
        },

        async updateLastPing() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            const pingRef = ref(rtdb, `rooms/${this.currentRoom}/participants/${authStore.user.uid}/lastPing`);
            await set(pingRef, new Date().toISOString());
        },

        async checkRoomNameDuplicate(name) {
            const roomsRef = ref(rtdb, 'rooms');
            const roomQuery = query(roomsRef, orderByChild('name'), equalTo(name));
            const snapshot = await get(roomQuery);
            return snapshot.exists();
        },

        async createRoom(roomData) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            await authStore.fetchUserProfile();
            const nickname = authStore.userProfile?.nickname || '익명';
            const roomsRef = ref(rtdb, 'rooms');
            const newRoomRef = push(roomsRef);

            const defaultRoomData = {
                bpm: 120,
                createdAt: new Date().toISOString(),
                createdBy: authStore.user.uid,
                hasPassword: false,
                isPlaying: false,
                isPublic: true,
                isPersonal: false,
                name: roomData.name || '새 방',
                participants: {},
                prebeat: 1,
                soundType: 'sine',
                startTime: Date.now(),
                timeSignature: '4/4',
                totalBeats: 0,
                volume: 0.5,
            };

            const finalRoomData = { ...defaultRoomData, ...roomData };

            if (finalRoomData.isPersonal) {
                finalRoomData.isPublic = false;
                finalRoomData.hasPassword = true;
                finalRoomData.password = authStore.user.uid;
            } else {
                finalRoomData.isPublic = !finalRoomData.password;
                finalRoomData.hasPassword = !!finalRoomData.password;
            }

            finalRoomData.participants[authStore.user.uid] = {
                nickname: nickname,
                joinedAt: new Date().toISOString(),
                lastPing: new Date().toISOString(),
                isHost: true
            };
            //로그기록
            trackCreateRoom(newRoomRef.key, roomData.isPersonal);

            await set(newRoomRef, finalRoomData);
            this.currentRoom = newRoomRef.key;
            this.isInRoom = true;
            this.currentRoomData = finalRoomData;
            localStorage.setItem('currentRoomId', newRoomRef.key);
            this.listenToRoomChanges();
            return newRoomRef.key;
        },

        async deleteRoom() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            await remove(roomRef);
            this.currentRoom = null;
            this.isInRoom = false;
            this.currentRoomData = null;
            localStorage.removeItem('currentRoomId');
        },

        async leaveRoom() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            // **수정: 리스너 해제**
            if (this.roomListener) {
                this.roomListener();
                this.roomListener = null;
            }

            const participantRef = ref(rtdb, `rooms/${this.currentRoom}/participants/${authStore.user.uid}`);
            await remove(participantRef);
            this.currentRoom = null;
            this.isInRoom = false;
            this.currentRoomData = null;
            localStorage.removeItem('currentRoomId');
        },

        async updateRoomSettings(roomId, settings) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !roomId) {
                console.error('User not authenticated or no roomId provided');
                return;
            }

            const roomRef = ref(rtdb, `rooms/${roomId}`);

            try {
                await update(roomRef, settings);

                if (this.rooms[roomId]) {
                    this.rooms[roomId] = { ...this.rooms[roomId], ...settings };
                }

                const updatedSnapshot = await get(roomRef);
                const updatedRoomData = updatedSnapshot.val();
                console.log('Updated room data from Firebase:', updatedRoomData);

                if (this.currentRoom === roomId) {
                    this.currentRoomData = updatedRoomData;
                }

            } catch (error) {
                console.error('Error updating room settings:', error);
                throw error;
            }
        },

        async updateCurrentBeat(roomId, currentBeat) {
            if (!roomId) {
                console.error('No roomId provided');
                return;
            }

            console.log(`Updating current beat for room ${roomId}: ${currentBeat}`);
            const currentBeatRef = ref(rtdb, `rooms/${roomId}/currentBeat`);
            await set(currentBeatRef, currentBeat);
            this.currentBeat = currentBeat;
            console.log(`Updated local current beat: ${this.currentBeat}`);
        },

        async updateCurrentAlbum(albumId) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            console.log('Updating current album:', albumId);
            const albumRef = ref(rtdb, `rooms/${this.currentRoom}/currentAlbum`);
            await set(albumRef, albumId);
            this.currentAlbumId = albumId;
            console.log(`Updated current album in room ${this.currentRoom}: ${albumId}`);
        },

        listenToRoomChanges() {
            if (!this.currentRoom) {
                console.error('방에 참여하지 않았습니다.');
                return;
            }

            console.log(`방 변경 사항을 듣고 있습니다: ${this.currentRoom}`);
            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);

            // **수정: 기존 리스너가 있으면 해제**
            if (this.roomListener) {
                this.roomListener();
                this.roomListener = null;
            }

            // 리스너 설정 및 저장
            this.roomListener = onValue(roomRef, (snapshot) => {
                const roomData = snapshot.val();
                if (roomData) {
                    this.rooms = { ...this.rooms, [this.currentRoom]: roomData };
                    this.currentRoomData = { ...roomData };
                    this.currentAlbumId = roomData.currentAlbum;

                    const metronomeStore = useMetronomeStore();
                    const isPlayingChanged = roomData.isPlaying !== metronomeStore.isPlaying;
                    const isBpmChanged = roomData.bpm !== metronomeStore.bpm;
                    const isCurrentBeatChanged = roomData.currentBeat !== metronomeStore.currentBeat;
                    const isLastStoppedMeasureChanged = roomData.lastStoppedMeasure !== metronomeStore.currentMeasure;

                    if (isPlayingChanged) {
                        if (roomData.isPlaying) {
                            metronomeStore.start(roomData.startTime);
                        } else {
                            metronomeStore.stop();
                        }
                    }

                    if (isCurrentBeatChanged) {
                        metronomeStore.setCurrentBeat(roomData.currentBeat);
                    }

                    if (isBpmChanged) {
                        metronomeStore.updateBPM(roomData.bpm);
                    }

                    // 마디 정보 동기화 (isPlaying이 false일 때만)
                    if (!roomData.isPlaying && isLastStoppedMeasureChanged) {
                        metronomeStore.setCurrentMeasure(roomData.lastStoppedMeasure);
                    }

                } else {
                    console.log(`방 ${this.currentRoom}이 더 이상 존재하지 않습니다.`);
                    this.rooms = { ...this.rooms };
                    delete this.rooms[this.currentRoom];
                    this.currentRoom = null;
                    this.isInRoom = false;
                    this.currentAlbumId = null;
                    this.currentRoomData = null;
                    localStorage.removeItem('currentRoomId');

                    // **수정: 리스너 해제**
                    if (this.roomListener) {
                        this.roomListener();
                        this.roomListener = null;
                    }
                }
            });
        },

        async checkRoomParticipation() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                this.isInRoom = false;
                this.currentRoom = null;
                localStorage.removeItem('currentRoomId');
                return false;
            }

            const storedRoomId = localStorage.getItem('currentRoomId');
            if (!storedRoomId) {
                this.isInRoom = false;
                this.currentRoom = null;
                return false;
            }

            const roomRef = ref(rtdb, `rooms/${storedRoomId}/participants/${authStore.user.uid}`);
            try {
                const snapshot = await get(roomRef);
                this.isInRoom = snapshot.exists();
                this.currentRoom = this.isInRoom ? storedRoomId : null;
                if (this.isInRoom) {
                    await this.fetchCurrentRoom(storedRoomId);
                    this.listenToRoomChanges();
                } else {
                    localStorage.removeItem('currentRoomId');
                }
                return this.isInRoom;
            } catch (error) {
                console.error("Error checking room participation:", error);
                this.isInRoom = false;
                this.currentRoom = null;
                this.currentRoomData = null;
                localStorage.removeItem('currentRoomId');
                return false;
            }
        },
    },
    getters: {
        computedRoomData: (state) => state.currentRoom ? state.rooms[state.currentRoom] || null : null, // getter 이름 변경
    },
});
