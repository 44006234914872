import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getDatabase, ref, onValue } from "firebase/database";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDib3vrG0CG4eFrR_LH-9ySb75__Q6Mpbc",
    authDomain: "feat-main-server.firebaseapp.com",
    databaseURL: "https://feat-main-server-default-rtdb.asia-southeast1.firebasedatabase.app/",
    projectId: "feat-main-server",
    storageBucket: "feat-main-server.appspot.com",
    messagingSenderId: "169206254431",
    appId: "1:169206254431:web:e5911a93af0d8a519f04ea",
    measurementId: "G-2MH3WHSPHN"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const rtdb = getDatabase(app);
const storage = getStorage(app);

const connectedRef = ref(rtdb, ".info/connected");
onValue(connectedRef, (snap) => {
    if (snap.val() === true) {
        console.log("Connected to Firebase RTDB");
    } else {
        console.log("Disconnected from Firebase RTDB");
    }
});

export { auth, db, functions, rtdb, storage };